var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('div',{staticClass:"rightAligned"},[_c('div',{staticStyle:{"display":"grid","place-items":"center"}},[_c('img',{staticClass:"image1",attrs:{"src":require('@/assets/dingwei1.png')}})]),_c('div',[_c('el-image',{staticClass:"image2",attrs:{"src":require('@/assets/dingwei2.jpg'),"preview-src-list":[require('@/assets/dingwei2.jpg')],"fit":"contain"}})],1),_c('div',{staticClass:"button1"},[_vm._v("扫码开始咨询")]),_c('div',{staticClass:"button2",staticStyle:{"font-weight":"bold"}},[_vm._v(" 扫码开始咨询 ")])])]),_c('div',{staticClass:"fiveInOne"},[_c('div',{staticClass:"t2",staticStyle:{"height":"1px"}}),_c('div',{staticClass:"body"},[_c('us-card',{style:({
backgroundImage: 'url(' + require('@/assets/five_in_one/bg.png') + ')',
backgroundSize: '100% 100%',
               cursor: 'pointer'

        }),attrs:{"title":'',"desc":'',"jump":() => {
            _vm.$emit('next')
          },"isClick":true}}),_c('us-card',{attrs:{"title":'大人体验官',"desc":'通过平台发布体验任务',"desc2":'邀请顾客到店消体验。',"desc3":'适用于新店新品体验、节庆推广、压力测试、人气活动。',"jump":() => {
          },"icon-url":require('@/assets/five_in_one/card1.png')}}),_c('us-card',{attrs:{"title":'达人探店',"desc":'通过平台发布探店任务',"desc2":'吸引行业影响者到店体验，通过他们的社交平台分享，增加店铺曝光度。',"desc3":'适用于获客、引流、促销售。',"jump":() => {
          },"icon-url":require('@/assets/five_in_one/card2.png')}}),_c('us-card',{attrs:{"title":'神秘顾客',"desc":'通过平台发布调查任务',"desc2":'招募实名制顾客以普通顾客身份对服务进行评估，品牌方通过报告发现风险点。',"desc3":'适用于督导稽核、员工培训、加盟店管理、顾客满意度提升。',"jump":() => {
            
          },"icon-url":require('@/assets/five_in_one/card3.png')}}),_c('us-card',{attrs:{"title":'轻咨询',"desc":'通过平台发布项目需求',"desc2":'依托灵活用工模式，匹配稽核、食安、策划等专家参与体系建设、稽核、辅导培训等。',"desc3":'适用于轻咨询项目、专项执行。',"jump":() => {
            
          },"icon-url":require('@/assets/five_in_one/card4.png')}}),_c('us-card',{attrs:{"title":'需求洞察',"desc":'通过平台发布在线调查',"desc2":'了解特定人群对品牌的体感、需求、消费偏好等。',"desc3":'适用于开店、产品研发、定价、SOP等决策。',"jump":() => {
            
          },"icon-url":require('@/assets/five_in_one/card5.png')}})],1),_c('div',{staticClass:"contact_us_bottom"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }