<template>
  <div
    class="card"
    @click="jump()"
  >
    <div :class="isClick ? 't2 t2link':'t2'">{{title}}</div>
    <div class="content">

      <div
        class="desc"
        v-if="desc"
      >{{desc}}，<span class="desc2">{{ desc2 }}</span>
        <div class="desc3">{{desc3}}</div>
      </div>
      <div class="icon">
        <img
          :src="iconUrl"
          v-if="iconUrl"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
    desc: {
      type: String,
      default: "",
    },
    desc2: {
      type: String,
      default: "",
    },
    desc3: {
      type: String,
      default: "",
    },

    jump: {
      type: Function,
      default: () => { }
    },
    isClick: {
      type: Boolean,
      default: false,
    },
    iconUrl: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() { },
  mounted() { }
};
</script>
<style lang='less' scoped>
/* 特定于桌面的样式 */
@media screen and (min-width: 1280px) {
  .card {
    width: calc(32% - 3rem);
    padding: 1.5rem;
    border-radius: 1rem;
    background: #fff;
    margin-bottom: 2rem;
    text-align: left;
    min-height: 14em;

    .t2 {
      font-size: 1.5rem;
      color: #111925;
      font-weight: bolder;
      line-height: 1.5;
      margin-bottom: 1rem;
    }
    .content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: start;
      .desc {
        font-size: 1rem;
        color: #111925;
        font-weight: 500;
        line-height: 1.7;
        flex: 4;
        padding-right: 0.5em;
      }
      .desc2 {
        font-size: 1rem;
        color: #111925;
        font-weight: 500;
        line-height: 1.7;
      }
      .desc3 {
        margin-bottom: 1rem;
        font-size: 1rem;
        color: #4072e2;
        font-weight: 500;
        line-height: 1.7;
      }
      .icon {
        flex: 1;
        img {
          width: 100%;
        }
      }
    }
    .t2link {
      color: #409eff !important;
    }
    .t2link:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
/* 特定于移动设备的样式 */
@media screen and (max-width: 1279px) {
  .card {
    width: calc(49% - 5rem);
    padding: 2.5rem;
    border-radius: 1rem;
    background: #fff;
    margin-bottom: 1.2rem;
    text-align: left;
    cursor: pointer;
    min-height: 14em;

    .t2 {
      margin-bottom: 1rem;
      font-size: 1.25rem;
      color: #111925;
      font-weight: 500;

      line-height: 1.5;
    }
    .content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: start;
      .desc {
        margin-bottom: 2rem;
        font-size: 0.825rem;
        color: #111925;
        font-weight: 500;
        line-height: 1.7;
        cursor: pointer;
        flex: 3;
        padding-right: 0.5em;
      }
      .desc2 {
        margin-bottom: 2rem;
        font-size: 0.825rem;
        color: #111925;
        font-weight: 500;
        line-height: 1.7;
        cursor: pointer;
      }
      .desc3 {
        margin-bottom: 2rem;
        font-size: 0.825rem;
        color: #4072e2;
        font-weight: 500;
        line-height: 1.7;
        cursor: pointer;
      }
      .icon {
        flex: 1;
        img {
          width: 100%;
        }
      }
    }
    .t2link {
      color: #409eff !important;
    }
    .t2link:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
/* 特定于移动设备的样式 */
@media screen and (max-width: 680px) {
  .card {
    width: calc(99% - 3rem);
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: #fff;
    margin-bottom: 0.7rem;
    text-align: left;
    cursor: pointer;
    min-height: 50vw;
    .t2 {
      margin-bottom: 1.125rem;
      font-size: 1.25rem;
      color: #111925;
      font-weight: 500;
      line-height: 1.5;
    }
    .content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: start;
      .desc {
        margin-bottom: 1.5rem;
        font-size: 0.75rem;
        color: #111925;
        font-weight: 500;
        line-height: 1.5;
        cursor: pointer;
        flex: 4;
        padding-right: 0.5em;
      }
      .desc2 {
        margin-bottom: 1.5rem;
        font-size: 0.75rem;
        color: #111925;
        font-weight: 500;
        line-height: 1.5;
        cursor: pointer;
      }
      .desc3 {
        margin-bottom: 1.5rem;
        font-size: 0.75rem;
        color: #4072e2;
        font-weight: 500;
        line-height: 1.5;
        cursor: pointer;
      }
      .icon {
        flex: 1;
        img {
          width: 100%;
        }
      }
    }
    .t2link {
      color: #409eff !important;
    }
    .t2link:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .card:last-child {
    // display: none;
  }
}
</style>