import Vue from 'vue'
import Vuex from 'vuex';
import App from './App.vue'
import $ from 'jquery';
import router from './router.js';
import './assets/iconfont/iconfont.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueAliplayer from "vue-aliplayer-v2";
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.config.productionTip = false
// 将jQuery绑定到Vue原型，以便在所有组件中都可以使用
Vue.prototype.$ = $;
Vue.use(ElementUI);
Vue.use(Vuex); 
Vue.use(VueAliplayer);
Vue.use(Vant);

Vue.prototype.$isMobile = () => { 
    const userAgent = navigator.userAgent.toLowerCase();
  const isMobileDevice = /iphone|ipod|android|windows phone|blackberry|webos|mobile/i.test(userAgent);
  const isSmallScreen = window.matchMedia("only screen and (max-width: 768px)").matches;
  return isMobileDevice || isSmallScreen;
}

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
