<template>
  <div>
    <div>
      <div class="rightAligned">
        <div style="display: grid; place-items: center">
          <img
            class="image1"
            :src="require('@/assets/dingwei1.png')"
          />
        </div>
        <div>
          <el-image
            class="image2"
            :src="require('@/assets/dingwei2.jpg')"
            :preview-src-list="[require('@/assets/dingwei2.jpg')]"
            fit="contain"
          />
        </div>
        <div class="button1">扫码开始咨询</div>
        <div
          class="button2"
          style="font-weight: bold"
        >
          扫码开始咨询
        </div>
      </div>
    </div>
    <div class="fiveInOne">
      <!-- <div class="t1">&nbsp;</div> -->
      <div
        class="t2"
        style="height: 1px;"
      ></div>
      <!-- <span class="icon iconfont">&#xe6b6;</span> -->
      <div class="body">
        <us-card
          :title="''"
          :desc="''"
          :jump="
            () => {
              $emit('next')
            }
          "
          :isClick="true"
          :style="{
  backgroundImage: 'url(' + require('@/assets/five_in_one/bg.png') + ')',
  backgroundSize: '100% 100%',
                 cursor: 'pointer'

          }"
        />

        <us-card
          :title="'大人体验官'"
          :desc="'通过平台发布体验任务'"
          :desc2="'邀请顾客到店消体验。'"
          :desc3="'适用于新店新品体验、节庆推广、压力测试、人气活动。'"
          :jump="
            () => {
            }
          "
          :icon-url="require('@/assets/five_in_one/card1.png') "
        />

        <us-card
          :title="'达人探店'"
          :desc="'通过平台发布探店任务'"
          :desc2="'吸引行业影响者到店体验，通过他们的社交平台分享，增加店铺曝光度。'"
          :desc3="'适用于获客、引流、促销售。'"
          :jump="
            () => {
            }
          "
          :icon-url="require('@/assets/five_in_one/card2.png') "
        />

        <us-card
          :title="'神秘顾客'"
          :desc="'通过平台发布调查任务'"
          :desc2="'招募实名制顾客以普通顾客身份对服务进行评估，品牌方通过报告发现风险点。'"
          :desc3="'适用于督导稽核、员工培训、加盟店管理、顾客满意度提升。'"
          :jump="
  () => {
              
            }
          "
          :icon-url="require('@/assets/five_in_one/card3.png') "
        />

        <us-card
          :title="'轻咨询'"
          :desc="'通过平台发布项目需求'"
          :desc2="'依托灵活用工模式，匹配稽核、食安、策划等专家参与体系建设、稽核、辅导培训等。'"
          :desc3="'适用于轻咨询项目、专项执行。'"
          :jump="
  () => {
              
            }
          "
          :icon-url="require('@/assets/five_in_one/card4.png') "
        />

        <us-card
          :title="'需求洞察'"
          :desc="'通过平台发布在线调查'"
          :desc2="'了解特定人群对品牌的体感、需求、消费偏好等。'"
          :desc3="'适用于开店、产品研发、定价、SOP等决策。'"
          :jump="
  () => {
              
            }
          "
          :icon-url="require('@/assets/five_in_one/card5.png') "
        />

      </div>

      <div class="contact_us_bottom">
        <!-- https://work.weixin.qq.com/kfid/kfc2d4e8a97f9ccb607 -->
        <!-- <span
        @click=" open('https://work.weixin.qq.com/kfid/kfc2d4e8a97f9ccb607')"
        class="lianxi"
      >服务咨询联系我们<span>点击联系客服</span></span> -->
      </div>
    </div>
  </div>
</template>

<script>
import card from "../components/five_in_one/card.vue";
export default {
  components: { "us-card": card },
  props: {},
  data() {
    return {
      contactUs: require("../assets/contact_us/contactUs.png"),
    };
  },
  watch: {},
  computed: {},
  methods: {
    liaojie() {
      window.open("https://yygj.darenlaiye.com/tuiguang2/index.html");
    },
    mailto(url) {
      window.location.href = "mailto:" + url;
    },
    baiduMap(url) {
      window.open(
        `http://api.map.baidu.com/geocoder?address=${url}&output=html&src=webapp.baidu.openAPIdemo`
      );
    },
    jump(url) {
      window.location.href = url;
    },
    open(url) {
      window.open(url);
    },
  },
  created() { },
  mounted() { },
};
</script>
<style lang='less' scoped>
.fiveInOne {
  max-width: 1280px;
  padding: 0px 20px;
  margin: 0px auto;
}

.rightAligned {
  z-index: 999;
  position: fixed;
  right: 10px; /* 这将元素定位到最右边 */
  top: 350px; /* 根据需要调整，这里是定位到最顶部 */
  .image1 {
    width: 110px;
    height: 75px;
  }
  .image2 {
    width: 150px;
    height: 150px;
    border-radius: 7px;
  }
  .button2 {
    display: none;
    font-weight: bold;
  }
  .button1 {
    margin-top: 2px;
    font-weight: bold;
    width: 150px;
    height: 30px;
    line-height: 17px;
    border-radius: 38px;
    background: linear-gradient(
      96.62deg,
      rgba(238, 75, 55, 1) 6.4%,
      rgba(222, 165, 79, 1) 95.57%
    );
    color: rgba(247, 248, 250, 1);
    font-size: 12px;
    text-align: center;
    font-family: Roboto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* 特定于桌面的样式 */
@media screen and (min-width: 1280px) {
  .fiveInOne {
    .t1 {
      font-size: 2.5rem;
      padding-top: 8rem;
      padding-bottom: 1.25rem;
      color: #111925;
      font-weight: 500;
      line-height: 1;
    }
    .t2 {
      margin-bottom: 2rem;
      margin-top: 2.5rem;
      font-size: 1.75rem;
      color: #111925;
      font-weight: 500;

      line-height: 1;
    }
    .body {
      margin-bottom: 1.25rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .contact_us_bottom {
      margin-bottom: 2.5rem;
    }
  }
}
/* 特定于移动设备的样式 */
@media screen and (max-width: 1279px) {
  .fiveInOne {
    .t1 {
      font-size: 2.5rem;
      padding-top: 8rem;
      padding-bottom: 1.25rem;
      color: #111925;
      font-weight: 500;
      line-height: 1;
    }
    .t2 {
      margin-bottom: 2rem;
      margin-top: 2.5rem;
      font-size: 1.75rem;
      color: #111925;
      font-weight: 500;

      line-height: 1;
    }
    .body {
      margin-bottom: 1.25rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .contact_us_bottom {
      margin-bottom: 2.5rem;
    }
  }
}
/* 特定于移动设备的样式 */
@media screen and (max-width: 1024px) {
  .fiveInOne {
    .t1 {
      font-size: 1.25rem;
      padding-top: 5rem;
      padding-bottom: 0.5rem;
      color: #111925;
      font-weight: 500;
      line-height: 1;
    }
    .t2 {
      margin-bottom: 0.75rem;
      margin-top: 2rem;
      font-size: 1.125rem;
      color: #111925;
      font-weight: 500;

      line-height: 1;
    }
    .body {
      margin-bottom: 1rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .contact_us_bottom {
      margin-bottom: 2rem;
    }
  }
}

/* 特定于移动设备的样式 */
@media screen and (max-width: 770px) {
  .rightAligned {
    z-index: 999;
    position: fixed;
    right: 10px; /* 这将元素定位到最右边 */
    top: 350px; /* 根据需要调整，这里是定位到最顶部 */
    .image1 {
      width: 75px;
      height: 50px;
    }
    .image2 {
      width: 100px;
      height: 100px;
      border-radius: 7px;
    }
    .button1 {
      display: none;
      font-weight: bold;
    }
    .button2 {
      margin-top: 1px;
      font-weight: bold;
      width: 100px;
      height: 22px;
      line-height: 17px;
      border-radius: 14px;
      background: linear-gradient(
        96.62deg,
        rgba(238, 75, 55, 1) 6.4%,
        rgba(222, 165, 79, 1) 95.57%
      );
      color: rgba(247, 248, 250, 1);
      font-size: 10px;
      text-align: center;
      font-family: Roboto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>