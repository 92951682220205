<template>
  <div>
    <div>
      <div class="rightAligned">
        <div style="display: grid; place-items: center">
          <img
            class="image1"
            :src="require('@/assets/dingwei1.png')"
          />
        </div>
        <div>
          <el-image
            class="image2"
            :src="require('@/assets/dingwei2.jpg')"
            :preview-src-list="[require('@/assets/dingwei2.jpg')]"
            fit="contain"
          />
        </div>
        <div class="button1">扫码开始咨询</div>
        <div
          class="button2"
          style="font-weight: bold"
        >
          扫码开始咨询
        </div>
      </div>
    </div>
    <div class="contactUs">
      <div class="t1">服务热线</div>
      <div class="t2">欢迎您通过邮件方式与我们取得联络</div>

      <div class="body">
        <us-card
          :title="'商务合作'"
          :desc="'biz@huami-tech.com'"
          :jump="
            () => {
              mailto('biz@huami-tech.com');
            }
          "
        />

        <us-card
          :title="'客服支持'"
          :desc="'service@huami-tech.com'"
          :jump="
            () => {
              mailto('service@huami-tech.com');
            }
          "
        />

        <us-card
          :title="'市场活动'"
          :desc="'marketing@huami-tech.com'"
          :jump="
            () => {
              mailto('marketing@huami-tech.com');
            }
          "
        />

        <us-card
          :title="'第三方开发'"
          :desc="'supports@huami-tech.com'"
          :jump="
            () => {
              mailto('supports@huami-tech.com');
            }
          "
        />

        <us-card
          :title="'人员招聘'"
          :desc="'hr@huami-tech.com'"
          :jump="
            () => {
              mailto('hr@huami-tech.com');
            }
          "
        />

        <us-card
          :title="'其他事务'"
          :desc="'darenlaiye@huami-tech.com'"
          :jump="
            () => {
              mailto('darenlaiye@huami-tech.com');
            }
          "
        />
        <us-card
          :title="'公司地址'"
          :desc="'上海市徐汇区乐山路33号3号楼'"
          :jump="
            () => {
              baiduMap('上海市徐汇区乐山路33号3号楼');
            }
          "
        />

        <us-card
          :title="'官网'"
          :desc="'www.darenlaiye.com'"
          :jump="
            () => {
              jump('https://www.darenlaiye.com');
            }
          "
        />

        <us-card
          :title="'服务咨询'"
          :desc="'联系微信客服'"
          :jump="
            () => {
  // open('https://work.weixin.qq.com/kfid/kfcac88be45ee8d1bc4');
              openPreview(require('@/assets/contact_us/H-Teacher.png') )
            }
          "
        />

        <us-card style="opacity: 0" />
      </div>

      <div class="contact_us_bottom">
        <!-- https://work.weixin.qq.com/kfid/kfc2d4e8a97f9ccb607 -->
        <!-- <span
        @click=" open('https://work.weixin.qq.com/kfid/kfc2d4e8a97f9ccb607')"
        class="lianxi"
      >服务咨询联系我们<span>点击联系客服</span></span> -->
      </div>
    </div>

    <!-- 图片预览对话框 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :top="'10vh'"
      width="50%"
      :before-close="handleClose"
    >
      <el-image
        :src="imageUrl"
        fit="contain"
        style="height: calc(80vh - 90px);margin: 0px auto;display: block;"
      ></el-image>
    </el-dialog>
    <van-dialog
      v-model="dialogVisible2"
      :confirmButtonText="'关闭'"
    >
      <div class="dialogVisible2">
        <img
          :src="imageUrl"
          style="width: 100%;"
        />
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { ImagePreview } from 'vant';
import card from "../components/contact_us/card.vue";
export default {
  components: { "us-card": card },
  props: {},
  data() {
    return {
      contactUs: require("../assets/contact_us/contactUs.png"),
      dialogVisible: false,  // 控制 Dialog 显示
      dialogVisible2: false,  // 控制 Dialog 显示
      imageUrl: '',         // 存放图片的 URL
    };
  },
  watch: {},
  computed: {},
  methods: {
    // 打开大图预览
    openPreview(url) {

      this.imageUrl = url;  // 设置图片的 URL
      if (this.$isMobile()) {
        this.dialogVisible2 = true;  // 显示 Dialog
      } else {
        this.dialogVisible = true;  // 显示 Dialog
      }
    },
    // 关闭预览
    handleClose() {
      this.dialogVisible = false;  // 关闭 Dialog
    },
    liaojie() {
      window.open("https://yygj.darenlaiye.com/tuiguang2/index.html");
    },
    mailto(url) {
      window.location.href = "mailto:" + url;
    },
    baiduMap(url) {
      window.open(
        `http://api.map.baidu.com/geocoder?address=${url}&output=html&src=webapp.baidu.openAPIdemo`
      );
    },
    jump(url) {
      window.location.href = url;
    },
    open(url) {
      window.open(url);
    },
  },
  created() { },
  mounted() { },
};
</script>
<style lang='less' scoped>
.contactUs {
  max-width: 1280px;
  padding: 0px 20px;
  margin: 0px auto;
}

.rightAligned {
  z-index: 999;
  position: fixed;
  right: 10px; /* 这将元素定位到最右边 */
  top: 350px; /* 根据需要调整，这里是定位到最顶部 */
  .image1 {
    width: 110px;
    height: 75px;
  }
  .image2 {
    width: 150px;
    height: 150px;
    border-radius: 7px;
  }
  .button2 {
    display: none;
    font-weight: bold;
  }
  .button1 {
    margin-top: 2px;
    font-weight: bold;
    width: 150px;
    height: 30px;
    line-height: 17px;
    border-radius: 38px;
    background: linear-gradient(
      96.62deg,
      rgba(238, 75, 55, 1) 6.4%,
      rgba(222, 165, 79, 1) 95.57%
    );
    color: rgba(247, 248, 250, 1);
    font-size: 12px;
    text-align: center;
    font-family: Roboto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* 特定于桌面的样式 */
@media screen and (min-width: 1280px) {
  .contactUs {
    .t1 {
      font-size: 2.5rem;
      padding-top: 8rem;
      padding-bottom: 1.25rem;
      color: #111925;
      font-weight: 500;
      line-height: 1;
    }
    .t2 {
      margin-bottom: 2rem;
      margin-top: 2.5rem;
      font-size: 1.75rem;
      color: #111925;
      font-weight: 500;

      line-height: 1;
    }
    .body {
      margin-bottom: 1.25rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .contact_us_bottom {
      margin-bottom: 2.5rem;
    }
  }
}
/* 特定于移动设备的样式 */
@media screen and (max-width: 1279px) {
  .contactUs {
    .t1 {
      font-size: 2.5rem;
      padding-top: 8rem;
      padding-bottom: 1.25rem;
      color: #111925;
      font-weight: 500;
      line-height: 1;
    }
    .t2 {
      margin-bottom: 2rem;
      margin-top: 2.5rem;
      font-size: 1.75rem;
      color: #111925;
      font-weight: 500;

      line-height: 1;
    }
    .body {
      margin-bottom: 1.25rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .contact_us_bottom {
      margin-bottom: 2.5rem;
    }
  }
}
/* 特定于移动设备的样式 */
@media screen and (max-width: 1024px) {
  .contactUs {
    .t1 {
      font-size: 1.25rem;
      padding-top: 5rem;
      padding-bottom: 0.5rem;
      color: #111925;
      font-weight: 500;
      line-height: 1;
    }
    .t2 {
      margin-bottom: 0.75rem;
      margin-top: 2rem;
      font-size: 1.125rem;
      color: #111925;
      font-weight: 500;

      line-height: 1;
    }
    .body {
      margin-bottom: 1rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .contact_us_bottom {
      margin-bottom: 2rem;
    }
  }
}

/* 特定于移动设备的样式 */
@media screen and (max-width: 770px) {
  .rightAligned {
    z-index: 999;
    position: fixed;
    right: 10px; /* 这将元素定位到最右边 */
    top: 350px; /* 根据需要调整，这里是定位到最顶部 */
    .image1 {
      width: 75px;
      height: 50px;
    }
    .image2 {
      width: 100px;
      height: 100px;
      border-radius: 7px;
    }
    .button1 {
      display: none;
      font-weight: bold;
    }
    .button2 {
      margin-top: 1px;
      font-weight: bold;
      width: 100px;
      height: 22px;
      line-height: 17px;
      border-radius: 14px;
      background: linear-gradient(
        96.62deg,
        rgba(238, 75, 55, 1) 6.4%,
        rgba(222, 165, 79, 1) 95.57%
      );
      color: rgba(247, 248, 250, 1);
      font-size: 10px;
      text-align: center;
      font-family: Roboto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>